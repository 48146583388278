<script setup>
import { computed } from 'vue';
import { useCollectStore } from '../stores/collect';
import axios from 'axios';

const props = defineProps({
  prodUuid: {
    type: String,
    required: true
  }
});

const collectStore = useCollectStore();

const isCollect = computed(() => {
  return collectStore.collectList.includes(props.prodUuid);
});

async function collectProduct(e) {
  e.preventDefault();

  const index = collectStore.collectList.indexOf(props.prodUuid);
  if (index > -1) {
    await axios.delete('favorite', { data: { uuid: props.prodUuid } });
    collectStore.collectList.splice(index, 1);
  } else {
    await axios.put('favorite', { uuid: props.prodUuid });
    collectStore.collectList.push(props.prodUuid);
  }
}
</script>

<template>
  <div class="btn--collect" :class="{ 'is-acted': isCollect }" @click="collectProduct">
    <svg class="svg-icon">
      <use xlink:href="#like_f" />
    </svg>
  </div>
</template>

<style lang="sass">
.btn--collect
  font-size: $font-l
  color: $font-light
  padding: 5px
  transition: all .5s ease
  cursor: pointer
  .svg-icon
    stroke: currentColor
    stroke-width: .08em
  &:not(.is-acted)
    .svg-icon
      fill: transparent
  &:hover
    color: $font-gray
  &.is-acted
    color: $primary-color
</style>
