<script setup>
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSearchStore } from '../../stores/search';
import { useSearch } from '../../composables/useSearch.js';
import GdsIcon from '../GdsIcon.vue';

const { locale } = useI18n();
const searchStore = useSearchStore();
const {
  actedContinent,
  setActedContinent,
  changeKeyword,
  changeCountry,
  changeCity,
  submitOrderSearch
} = useSearch();

const isShowDest = ref(false);
watch(() => searchStore.condition.keyword, value => {
  if (value === '') 
    isShowDest.value = true;
  else
    isShowDest.value = false;
});

onMounted(async () => {
  await searchStore.setDestinations(locale.value);
  if (searchStore.condition.keyword && searchStore.condition.type === '') 
    await searchStore.checkKeywordIsDest();
  
  setActedContinent();
  document.addEventListener('click', documentClick);
  document.addEventListener('keydown', focusSearchInput);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', documentClick);
  document.removeEventListener('keydown', focusSearchInput);
});

const searchDom = ref(null);
const searchDestDom = ref(null);
const tabProductDom = ref(null);
function documentClick(e) {
  const target = e.target;
  if (!searchDom.value)
    searchDom.value = document.getElementById('searchDom');

  if (!searchDestDom.value)
    searchDestDom.value = document.getElementById('searchDestDom');

  if (!tabProductDom.value)
    tabProductDom.value = document.getElementById('tabProductDom');

  if (!searchDom.value.contains(target) && !searchDestDom.value.contains(target) && ((tabProductDom.value && !tabProductDom.value.contains(target)) || !tabProductDom.value)) 
    isShowDest.value = false;
}

// 清除產品關鍵字查詢
const searchInputDom = ref(null);
async function clearSearch() {
  actedContinent.value = '';
  searchStore.clearCondition();

  searchInputDom.value.focus();
}

// 清除訂單關鍵字查詢
const orderSearchInputDom = ref(null);
function clearOrderSearch() {
  searchStore.clearOrderKeyword();
  orderSearchInputDom.value.focus();
}

// 切換查詢分類
function focusSearchInput(e) {
  if (e.key === '/') {
    e.preventDefault();
    if (searchStore.searchType === 'product')
      searchInputDom.value.focus();
    else if (searchStore.searchType === 'order')
      orderSearchInputDom.value.focus();
  }
}
defineExpose({ toggleSearchType });
async function toggleSearchType(type) {
  searchStore.searchType = type;
  await nextTick();
  if (type === 'product') {
    clearSearch();
  } else if (type === 'order') {
    clearOrderSearch();
    isShowDest.value = false;
  }
}

function isDestColsTwo(countryCode) {
  return (
    (actedContinent.value === 'Southeast Asia' || actedContinent.value === 'Oceania' || (actedContinent.value === 'North-east Asia' && countryCode === 'KR')) &&
    locale.value === 'ja'
  );
}

function checkKeyword() {
  if (searchStore.condition.type === 'country' || searchStore.condition.type === 'city' || searchStore.condition.keyword === '') 
    isShowDest.value = true;
}

function changeSearchKeyword() {
  searchInputDom.value.blur();
  changeKeyword();
}

function changeContinent(continent) {
  actedContinent.value = continent;
}

function changeSearchCountry(country) {
  changeCountry(country);
  isShowDest.value = false;
}

function changeSearchCity(city) {
  changeCity(city);
  isShowDest.value = false;
}
</script>

<template>
  <div id="gds-search-dest" class="gds-search-wrap">
    <div ref="searchDom" id="searchDom" class="gds-search round-pill flex">
      <select class="search-type font-ss round-pill"
              v-model="searchStore.searchType"
              @change="toggleSearchType(searchStore.searchType)">
        <option value="product">票券</option>
        <option value="order">訂單</option>
      </select>
      <input v-show="searchStore.searchType === 'product'"
             ref="searchInputDom"
             type="text"
             class="search-input flex-1"
             :class="{'search-dest':
               searchStore.condition.type === 'country' || searchStore.condition.type === 'city'
             }"
             :placeholder="$t('common.enter_keyword')"
             v-model="searchStore.condition.keyword"
             @focus="checkKeyword"
             @input="searchStore.condition.type = ''"
             @keyup.enter="changeSearchKeyword" />
      <input v-show="searchStore.searchType === 'order'"
             ref="orderSearchInputDom"
             type="text"
             class="search-input flex-1"
             :placeholder="$t('order.keyword_placeholder')"
             v-model="searchStore.orderKeyword"
             @keyup.enter="submitOrderSearch" />
      <gds-icon v-if="searchStore.searchType === 'product' && (searchStore.condition.type === 'country' || searchStore.condition.type === 'city')"
                class="icon-dest"
                icon="map-b" />
      <div v-show="(searchStore.searchType === 'product' && searchStore.condition.keyword) || (searchStore.searchType === 'order' && searchStore.orderKeyword)"
           class="search-clear flex items-center justify-center"
           @click="searchStore.searchType === 'order' ? clearOrderSearch() : clearSearch()">
        <gds-icon icon="plus-circle-b" />
      </div>
      <button type="button" class="search-btn round-circle" @click="searchStore.searchType === 'order' ? submitOrderSearch() : changeSearchKeyword()">
        <gds-icon icon="search" />
      </button>
    </div>
    <transition name="fade-in">
      <div v-show="searchStore.destGrouping.length > 0 && isShowDest"
           ref="searchDestDom"
           id="searchDestDom"
           class="gds-dest">
        <div class="dest-wrap flex">
          <ul class="dest-menu">
            <li v-for="(continent, continentKey) in searchStore.destGrouping"
                :key="continent.name"
                class="menu-item"
                :class="{'is-acted':
                  continent.name === actedContinent || (actedContinent === '' && continentKey === 0)
                }"
                @click="changeContinent(continent.name)">
              {{ $t('continent.' + continent.name) }}
            </li>
          </ul>
          <div class="dest-content flex-1">
            <div v-show="continent.name === actedContinent || (actedContinent === '' && continentKey === 0)"
                 v-for="(continent, continentKey) in searchStore.destGrouping"
                 :key="continent.name"
                 class="dest-inner row-gap-20">
              <div v-for="country in continent.countries" :key="country.code">
                <div v-if="country.cities.length > 0" class="row-gap-3">
                  <span class="dest-label"
                        :class="{'is-acted':
                          searchStore.countryList[country.code] === searchStore.condition.keyword &&
                          searchStore.condition.country
                        }"
                        @click="changeSearchCountry(country.code)">
                    {{ searchStore.countryList[country.code] }}
                  </span>
                  <div class="dest-list" :class="{ 'cols-two': isDestColsTwo(country.code) }">
                    <div v-for="city in country.cities" :key="city">
                      <div class="dest-item"
                           :class="{'is-acted':
                             searchStore.cityList[city] === searchStore.condition.keyword &&
                             searchStore.condition.city
                           }"
                           @click="changeSearchCity(city)">
                        {{ searchStore.cityList[city] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="sass">
.gds-search-wrap
  position: relative
.gds-search
  position: relative
  background-color: #fff
  overflow: hidden
  .search-type
    height: 32px
    color: $font-dark
    background-color: $border-lighter
    background-position: right 0.55rem center
    border: 0
    padding: 0 25px 0 10px
    margin: 4px
  .search-input
    height: 40px
    color: $secondary-color
    border: 0
    &::placeholder
      color: #ccc
    &:focus ~ .search-btn
      color: #fff
      background-color: $secondary-color
    &.search-dest
      padding-left: 1.2rem
  .icon-dest
    +psa(11px, null, null, 4.2rem)
    color: $secondary-color
  .search-clear
    +psa(null, 40px)
    width: 40px
    height: 40px
    cursor: pointer
    .gds-icon
      font-size: $font-l
      color: $font-gray
      transform: rotate(45deg)
  .search-btn
    outline: none
    width: 32px
    height: 32px
    color: $font-gray
    background-color: $border-lighter
    border: 0
    margin: 4px 6px
    transition: .3s ease
    cursor: pointer
.gds-dest
  +psa(38px, null, null, 0, $z-index-dest)
  min-width: 100%
  padding-top: 8px
  .dest-wrap
    min-height: 340px
    background-color: #fff
    border-radius: 15px
    box-shadow: 2px 2px 10px rgba(153, 153, 153, .4)
    overflow: hidden
  .dest-menu
    background-color: $bg-color
    border-right: 1px solid $border-light
  .menu-item
    min-width: 112px
    text-align: center
    font-size: $font-m
    color: $font-dark
    padding: 15px 15px 15px 12px
    border-bottom: 1px solid $border-light
    margin-right: -1px
    cursor: pointer
    white-space: nowrap
    &.is-acted
      font-weight: $font-medium
      background-color: #fff
      color: $primary-color
  .dest-content
    max-height: 340px
    color: $font-darker
    padding: 5px
    overflow-y: scroll
  .dest-inner
    padding: 20px
  .dest-label
    display: inline-block
    text-decoration: underline
    font-weight: $font-black
    font-size: $font-m
    padding: 8px
    border-radius: 3px
    cursor: pointer
    &:hover, &.is-acted
      text-decoration: none
      color: #fff
      background-color: $primary-color
  .dest-list
    --cols: 4
    display: grid
    grid-template-columns: repeat(var(--cols), 1fr)
    gap: 5px
    min-width: 550px
    &.cols-two
      --cols: 2
  .dest-item
    padding: 8px
    border-radius: 3px
    cursor: pointer
    white-space: nowrap
    &:hover, &.is-acted
      color: #fff
      background-color: $primary-color
</style>
