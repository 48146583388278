import { reactive, computed } from 'vue';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', () => {
  let session = null;
  if (localStorage.getItem('gds-session')) 
    session = JSON.parse(localStorage.getItem('gds-session'));
  
  const state = reactive(session ? session : {
    userId: '',
    userName: '',
    apiToken: '',
    client: '',
    clientContact: '',
    currency: 'TWD',
    locale: 'zh-hant',
    expires: ''
  });

  const isLogin = computed(() => {
    let now = new Date().getTime();
    if (state.apiToken && state.client && now < state.expires)
      return true;
    else
      return false;
  });

  const isAdmin = computed(() => state.client.auth > 1);

  function setUserClient(payload) {
    state.userId = payload.userId;
    state.userName = payload.userName;
    state.apiToken = payload.apiToken;
    state.client = payload.client;
    if (payload.clientContact.length === 0) {
      state.clientContact = [
        {
          name: '黃昌業',
          phone: '02-87939000#2564',
          email: 'jackchuang@liontravel.com'
        }
      ];
    } else 
      state.clientContact = payload.clientContact;
    
    state.expires = new Date().getTime() + 86400000;
    localStorage.setItem('gds-session', JSON.stringify(state));
  }

  function setAuth(auth) {
    state.client.auth = auth;
    localStorage.setItem('gds-session', JSON.stringify(state));
  }

  function setCurrency(currency) {
    state.currency = currency;
    localStorage.setItem('gds-session', JSON.stringify(state));
  }

  function setLocale(locale) {
    state.locale = locale;
    localStorage.setItem('gds-session', JSON.stringify(state));
  }

  function logout() {
    state.userId = '';
    state.userName = '';
    state.apiToken = '';
    state.client = '';
    state.clientContact = '';
    state.expires = '';
    localStorage.removeItem('gds-session');
  }

  return {
    state,
    isLogin,
    isAdmin,
    setUserClient,
    setAuth,
    setCurrency,
    setLocale,
    logout
  };
});
