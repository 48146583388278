import { createRouter, createWebHistory } from 'vue-router';
import i18n from '../i18n';
import { useAuthStore } from '../stores/auth';
import { useCollectStore } from '../stores/collect';
import { useCurrencyStore } from '../stores/currency';
import { useSearchStore } from '../stores/search';
import HomePage from '../views/HomePage.vue';
import axios from 'axios';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: HomePage,
      meta: { requiresAuth: true }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutUs.vue'),
      meta: {
        title: i18n.global.t('title.about')
      }
    },
    {
      path: '/productlist',
      name: 'productList',
      component: () => import('../views/ProductList.vue'),
      meta: {
        title: i18n.global.t('title.product_list'),
        requiresAuth: true
      }
    },
    {
      path: '/carlist/:planCode?',
      name: 'carList',
      component: () => import('../views/CarList.vue'),
      meta: {
        isRent: true,
        title: i18n.global.t('title.car_list'),
        requiresAuth: true
      }
    },
    {
      path: '/product/:uuid',
      name: 'product',
      component: () => import('../views/ProductDetail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/booking',
      name: 'booking',
      component: () => import('../views/BookingQuestion.vue'),
      meta: {
        title: i18n.global.t('title.booking_question'),
        requiresAuth: true
      }
    },
    {
      path: '/bookingdraft',
      name: 'bookingDraft',
      component: () => import('../views/BookingDraft.vue'),
      meta: {
        title: i18n.global.t('title.booking_draft'),
        requiresAuth: true
      }
    },
    {
      path: '/bookingdone/:uuid',
      name: 'bookingDone',
      component: () => import('../views/BookingDone.vue'),
      meta: {
        title: i18n.global.t('title.booking_done'),
        requiresAuth: true
      }
    },
    {
      path: '/bookingbatch',
      name: 'bookingBatch',
      component: () => import('../views/BookingBatch.vue'),
      meta: {
        title: i18n.global.t('title.booking_batch'),
        requiresAuth: true
      }
    },
    {
      path: '/orderlist',
      name: 'orderList',
      component: () => import('../views/OrderList.vue'),
      meta: {
        title: i18n.global.t('title.order_list'),
        requiresAuth: true
      }
    },
    {
      path: '/order/:uuid',
      name: 'order',
      component: () => import('../views/OrderDetail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/postlist',
      name: 'postList',
      component: () => import('../views/PostList.vue'),
      meta: {
        title: i18n.global.t('title.post_list'),
        requiresAuth: true
      }
    },
    {
      path: '/post/:id',
      name: 'post',
      component: () => import('../views/PostDetail.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/client',
      name: 'client',
      component: () => import('../views/ClientProfile.vue'),
      meta: {
        title: i18n.global.t('title.client'),
        requiresAuth: true
      }
    },
    {
      path: '/accountlist',
      name: 'accountList',
      component: () => import('../views/AccountList.vue'),
      meta: {
        isAdmin: true,
        title: i18n.global.t('title.account_list'),
        requiresAuth: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/UserProfile.vue'),
      meta: {
        title: i18n.global.t('title.profile'),
        requiresAuth: true
      }
    },
    {
      path: '/collectlist',
      name: 'collectList',
      component: () => import('../views/CollectList.vue'),
      meta: {
        title: i18n.global.t('title.collect_list'),
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginPage.vue'),
      meta: {
        title: i18n.global.t('title.login')
      }
    },
    {
      path: '/autoLogin/:user_code',
      name: 'autoLogin',
      component: () => import('../views/AutoLogin.vue')
    },
    // {
    //   path: '/nftcheck',
    //   name: 'nftCheck',
    //   component: () => import('../views/NftCheck.vue')
    // },
    {
      path: '/nftresult/:metamaskAccount',
      name: 'nftResult',
      component: () => import('../views/NftResult.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: () => import('../views/ErrorPage.vue'),
      meta: { requiresAuth: true }
    }
  ]
});

router.beforeEach((to, from) => {
  const authStore = useAuthStore();
  const searchStore = useSearchStore();
  const collectStore = useCollectStore();
  useCurrencyStore();

  if (to.meta.title)
    document.title = to.meta.title + ' - ' + i18n.global.t('common.website_name');
  else if (['order', 'product', 'post'].includes(to.name)) {

  } else
    document.title = i18n.global.t('common.website_name');

  //配置请求的跟路径
  // axios.defaults.baseURL = 'http://b2bweb.kaobei.tw';
  //跨域请求设置
  // axios.defaults.withCredentials = true
  axios.defaults.crossDomain = true
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.get('/token').then(res => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = res.data;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  });

  if (authStore.isLogin && to.name !== 'productList' && to.name !== 'product')
    searchStore.clearCondition();
  else if (to.query.keyword)
    searchStore.condition.keyword = to.query.keyword;

  if (authStore.isLogin && to.name !== 'orderList' && to.name !== 'order')
    searchStore.clearOrderKeyword();
  else if (to.query.keyword)
    searchStore.orderKeyword = to.query.keyword;

  if (collectStore.collectList.length === 0 && to.name !== 'about' && to.name !== 'login')
    collectStore.setCollect();

  if (to.meta.requiresAuth && !authStore.isLogin && to.name !== 'login' && to.name !== 'autoLogin') {
    if (to.redirectedFrom)
      localStorage.setItem('redirect', to.redirectedFrom.name);

    return { name: 'login' };
  } else if (authStore.isLogin && (to.name === 'login' || to.name === 'autoLogin'))
    return { name: 'index' };
  else if ((to.meta.isAdmin && !authStore.isAdmin) || (to.meta.isRent && !authStore.state.client.allow_car_rental)) {
    return {
      name: 'error',
      query: {
        code: '401'
      }
    };
  }
});

router.afterEach((to, from) => {
  if (to.name === from.name) {
    // window.location = to.href;
  }
});

export default router;
