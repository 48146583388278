import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useDownloadStore = defineStore('download', () => {
  const isDownloading = ref(false);
  const fileName = ref('');
  const progress = ref(0);

  function setLoader(payload) {
    isDownloading.value = payload.isDownloading;
    fileName.value = payload.fileName;
  }

  function clearLoader() {
    isDownloading.value = false;
    fileName.value = '';
    progress.value = 0;
  }

  return {
    isDownloading,
    fileName,
    progress,
    setLoader,
    clearLoader
  };
});
