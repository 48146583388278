<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../../stores/auth';
import GdsIcon from '../GdsIcon.vue';
import SearchBar from './SearchBar.vue';

const LOCALES = ['zh-hant', 'zh-hans', 'en', 'ja'];
const CURRENCIES = ['TWD', 'RMB', 'USD', 'JPY', 'THB', 'KOW', 'GBP', 'AUD', 'CAD'];

defineProps({
  isSearch: {
    type: Boolean,
    default: true
  }
});

const router = useRouter();
const authStore = useAuthStore();
const hostName = ref(window.location.hostname);

function changeCurrency(currency) {
  authStore.setCurrency(currency);
}

function changeLocale(localeCode) {
  authStore.setLocale(localeCode);
  router.go(0);
}

function logout() {
  authStore.logout();
  router.push({ name: 'login' });
}
</script>

<template>
  <div id="header-pc" class="flex item-center justify-between only-pc">
    <div class="flex items-center">
      <router-link id="headerPcLinkIndex" class="logo-link flex items-center col-gap-5" to="/">
        <img src="/res/logo-white.svg" class="logo" />
        <span class="logo-title"><small v-if="hostName != 'www.ticketgds.com'">sandbox</small>{{ $t('common.website_name') }}</span>
      </router-link>
      <search-bar v-if="isSearch" />
    </div>
    <div class="flex items-center col-gap-30">
      <router-link v-if="authStore.state.client.allow_car_rental" id="headerPcLinkCarRental" class="nav-item" :to="{ name: 'carList' }">
        {{ $t('title.car_list') }}
      </router-link>
      <router-link id="headerPcLinkOrderList" class="nav-item" :to="{ name: 'orderList' }">
        {{ $t('title.order_list') }}
      </router-link>
      <div class="dropdown-control">
        <span>{{ authStore.state.currency }}</span>
        <div class="dropdown-content">
          <ul class="dropdown-list">
            <li v-for="currency in CURRENCIES" :key="currency" @click="changeCurrency(currency)">
              <span class="title" :class="{ 'is-acted': currency == authStore.state.currency }">
                {{ currency }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="language dropdown-control">
        <span>{{ $t('lang_name') }}</span>
        <div class="dropdown-content">
          <ul class="dropdown-list">
            <li v-for="localeCode in LOCALES" :key="localeCode" @click="changeLocale(localeCode)">
              <span class="title" :class="{ 'is-acted': localeCode === authStore.state.locale }">
                {{ $t('lang_name', null, { locale: localeCode }) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row-gap-3">
        <div>{{ authStore.state.client.name }}</div>
        <div class="dropdown-control flex items-center justify-end col-gap-5">
          <gds-icon icon="user" />
          <div class="user-name">
            <span>{{ authStore.state.userName }}</span>
            <div class="dropdown-content content-right">
              <ul class="dropdown-list">
                <li>
                  <router-link class="title"
                               :class="{ 'is-acted': $route.name == 'client' }"
                               to="/client">
                    {{ $t('title.client') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="title"
                               :class="{ 'is-acted': $route.name == 'profile' }"
                               to="/profile">
                    {{ $t('title.profile') }}
                  </router-link>
                </li>
                <li>
                  <router-link id="menuPcLinkCollect"
                               class="title"
                               :class="{ 'is-acted': $route.name == 'collectList' }"
                               to="/collectlist">
                    {{ $t('title.collect_list') }}
                  </router-link>
                </li>
                <li>
                  <router-link id="menuPcLinkOrder"
                               class="title"
                               :class="{ 'is-acted': $route.name == 'orderList' }"
                               to="/orderlist">
                    {{ $t('title.order_list') }}
                  </router-link>
                </li>
                <li>
                  <router-link id="menuPcLinkDraft"
                               class="title"
                               :class="{ 'is-acted': $route.name == 'bookingDraft' }"
                               to="/bookingdraft">
                    {{ $t('title.booking_draft') }}
                  </router-link>
                </li>
                <li v-if="authStore.isAdmin">
                  <router-link class="title"
                               :class="{ 'is-acted': $route.name == 'accountList' }"
                               to="/accountlist">
                    {{ $t('title.account_list') }}
                  </router-link>
                </li>
                <li @click="logout">
                  <span class="title">{{ $t('common.logout') }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
#header-pc
  +psf(0, 0, null, null, $z-index-topbar)
  width: 100vw
  height: 60px
  font-size: $font-ss
  color: #fff
  background-color: $primary-color
  padding: 10px 1rem
  white-space: nowrap
  box-shadow: 0 1px 12px rgba(0, 0, 0, .4)
  .logo-link
    text-decoration: none
  .logo
    width: 30px
  .logo-title
    font-size: $font-l
    color: #fff
    small
      display: block
      font-size: .65rem
      margin-top: -3px
  .tag-beta
    font-size: $font-xs
    color: #fff
    margin-top: 5px
  .gds-search-wrap
    width: 300px
    margin-left: 10px
  .nav-item
    text-decoration: none
    color: #fff
  .language
    width: 105px
    padding-right: 30px
    border-right: 1px solid #ff7d7d
  .user-name
    font-size: $font-xs
  .dropdown-control
    position: relative
    cursor: pointer
    &:hover
      .dropdown-content
        display: block
    &::after
      content: ''
      display: inline-block
      width: 6px
      height: 6px
      border-bottom: 1px solid #fff
      border-right: 1px solid #fff
      margin: 0 0 3px 10px
      transform: rotate(45deg)
    .dropdown-content
      display: none
      +psa(18px, 50%, null, null, $z-index-dest)
      padding-top: 10px
      transform: translateX(50%)
      &.content-right
        right: -25px
        transform: translateX(0)
        ul::before
          left: 85%
    ul
      position: relative
      box-shadow: 2px 2px 10px rgba(153, 153, 153, .4)
      &::before
        +psa(-5px, null, null, 50%)
        content: ''
        display: inline-block
        width: 8px
        height: 8px
        background-color: #fff
        border-top: 1px solid $border-light
        border-left: 1px solid $border-light
        transform: translateX(-50%) rotate(45deg)
  .dropdown-list
    white-space: nowrap
    background-color: #fff
    padding: .8rem 1rem
    border: 1px solid $border-light
    border-radius: 15px
    li
      border-radius: 5px
      cursor: pointer
      &:hover
        background-color: $bg-color
    .title
      display: block
      text-decoration: none
      color: $font-darker
      padding: .8rem 2rem
      &.is-acted
        color: $secondary-color

+breakpoint('min-width', 1300)
  #header-pc
    padding: 10px 3rem
    .gds-search-wrap
      width: 380px
      margin-left: 30px
</style>
