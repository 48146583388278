import axios from 'axios';
import { useAuthStore } from '../stores/auth';

/**
 * 攔截器函數:
 *
 * 攔截器有三種時機, 建議用三種不同的命名原則區隔方便閱讀
 * -   請求前: requestSomething()
 * - 回應成功: okaySomething()
 * - 回應失敗: errorSomething()
 */

function requestCheatToken(config) {
  const LOCAL = (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost');
  if (LOCAL)
    // config.baseURL = 'https://www.ticketgds.com/api'; // 正式機
    config.baseURL = 'https://gds-b2bweb.ticketgds.com/api'; // 測試機
    // config.baseURL = 'http://gdsmid.kaobei.tw/api'; // 開發機
  else
    config.baseURL = window.location.origin + '/api';

  config.headers['Content-Type'] = 'application/json';
  config.metadata = { startTime: performance.now() };

  if (config.url === 'otp-auth') {
    // 登入時不注入 session 資訊
    return config;
  } else {
    // 注入 session 資訊
    const authStore = useAuthStore();
    config.headers.Authorization = 'Bearer ' + authStore.state.apiToken;
    config.headers['X-Client-UUID'] = authStore.state.client.uuid;
    return config;
  }
}

function errorRequest(error) {
  console.error(error);
  return Promise.reject(error);
}

function okayCounter(response) {
  // console.log("Interceptor okayCounter() triggered!")
  response.duration = Math.round(performance.now() - response.config.metadata.startTime);
  return response;
}

function errorCounter(error) {
  // 非 api 定義的（ ErrorDefs ）錯誤
  if (error.response && error.response.status === 401) {
    // 登入錯誤
    console.warn('api_token 已失效');
  } else {
    console.error(error);
    return Promise.reject(error);
  }
}

export default function () {
  // console.log("配置 axios 攔截器")
  axios.interceptors.request.use(requestCheatToken, errorRequest);
  axios.interceptors.response.use(okayCounter, errorCounter);
}
