<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '../stores/auth';
import { useSearchStore } from '../stores/search';
import { useSearch } from '../composables/useSearch.js';
import { useGdsFunc } from '../composables/useGdsFunc.js';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import axios from 'axios';
import GdsIcon from '../components/GdsIcon.vue';
import LoaderSpinner from '../components/LoaderSpinner.vue';
import HeaderPc from '../components/pc/HeaderPc.vue';
import HeaderMobile from '../components/m/HeaderMobile.vue';
import SearchBar from '../components/pc/SearchBar.vue';
import CardProduct from '../components/CardProduct.vue';
import FooterLoader from '../components/FooterLoader.vue';

import '@splidejs/vue-splide/css';

const { locale } = useI18n();
const authStore = useAuthStore();
const searchStore = useSearchStore();
const { formatDate, stripTags, errorHandler, setMetaTitle } = useGdsFunc();

const slides = reactive([]);
const bannerSplideOptions = reactive({
  type: 'loop',
  speed: 800,
  autoplay: true,
  interval: 4000,
  breakpoints: {
    760: {
      arrows: false
    }
  }
});
const productSplideOptions = reactive({
  speed: 800,
  pagination: false,
  perPage: 3,
  breakpoints: {
    760: {
      perPage: 1,
      arrows: false,
      pagination: true
    },
    960: {
      perPage: 2,
      arrows: false,
      pagination: true
    },
    1050: {
      arrows: false,
      pagination: true
    }
  }
});

function responseTime(time) {
  time = new Date().getTime() - time;
  if (Math.floor(time / 1000) > 0)
    return time / 1000 + ' s';
  else
    return time + ' ms';
}

function responseSize(resp) {
  let size = JSON.stringify(resp.data).length + JSON.stringify(resp.headers).length;
  if (size / 1000 > 0)
    return size / 1000 + ' kB';
  else
    return size + ' B';
}

const isLoading = ref(true);
onMounted(() => {
  document.querySelector('body').classList.add('index');
  Promise.all([loadIndex(), topsale(), latest(), loadKeyword()]).then(() => {
    if (recentSearchKeywords.value.length === 0) {
      recentSearchKeywordsLabel.value = '熱門關鍵字：';
      recentSearchKeywords.value.push('迪士尼');
    }
    isLoading.value = false;
  });
});
onBeforeUnmount(() => {
  document.querySelector('body').classList.remove('index');
});

const headerDom = ref(null);
function triggerMobileSearch() {
  headerDom.value.showSearchMenu();
}

const isShowForcePost = ref(false);
const post_popup_title = ref('');
const post_popup_content = ref('');
const posts = ref([]);
const promoProduct = ref([]);
const latestBuyProduct = ref([]);
async function loadIndex() {
  try {
    let startTime = new Date().getTime();
    await axios.get('index', {
      params: {
        lang: locale.value.toUpperCase()
      }
    }).then(resp => {
      if (authStore.state.client.auth === 3)
        console.info('index: ' + responseSize(resp) + '/ ' + responseTime(startTime));

      if (resp.data.md_status === 0) {
        posts.value = resp.data.result.posts;
        promoProduct.value = resp.data.result.products;
        latestBuyProduct.value = resp.data.result.latest_buy;

        let browser_force_read = localStorage.getItem("gds_b2bweb_post_force_read");
        let force_post_id = 0;
        let force_post = {};
        if (resp.data.result.posts.length > 0) {
          resp.data.result.posts.forEach((post, key) => {
            if (post.force_read == 1) {
              // 顯示最新的強制閱讀文章
              if(post.id > force_post_id) {
                force_post_id = post.id;
                force_post = post;
              }
            }
          });
        }

        if (force_post_id && browser_force_read != force_post_id) {
          localStorage.setItem("gds_b2bweb_post_force_read", force_post_id);
          post_popup_title.value = force_post.title;
          post_popup_content.value = force_post.content;
          isShowForcePost.value = true;
          document.body.classList.add('body-fixed');
        }

      } else
        throw resp;
    });
  } catch (e) {
    errorHandler(e);
  }
}

function clzForcePost() {
  isShowForcePost.value = false;
  document.body.classList.remove('body-fixed');
}

const topsaleProduct = ref([]);
// 熱門產品
async function topsale() {
  try {
    let startTime = new Date().getTime();
    await axios.get('topsale', {
      params: {
        lang: locale.value.toUpperCase(),
        limit: 10
      }
    }).then(resp => {
      if (authStore.state.client.auth === 3)
        console.info('topsale: ' + responseSize(resp) + '/ ' + responseTime(startTime));

      if (resp.data.md_status === 0)
        topsaleProduct.value = resp.data.result;
      else
        throw resp;
    });
  } catch (e) {
    errorHandler(e);
  }
}

const latestProduct = ref([]);
// 最新商品
async function latest() {
  try {
    let startTime = new Date().getTime();
    await axios.get('latest', {
      params: {
        lang: locale.value.toUpperCase(),
        limit: 10
      }
    }).then(resp => {
      if (authStore.state.client.auth === 3)
        console.info('latest: ' + responseSize(resp) + '/ ' + responseTime(startTime));

      if (resp.data.md_status === 0)
        latestProduct.value = resp.data.result;
      else
        throw resp;
    });
  } catch (e) {
    errorHandler(e);
  }
}

const recentSearchKeywordsLabel = ref('之前搜尋過：');
const recentSearchKeywords = ref([]);
const recentOrderSearchKeywords = ref([]);
async function loadKeyword() {
  if (localStorage.getItem('search')) {
    recentSearchKeywords.value = localStorage.getItem('search').split(',');
  }
  if (localStorage.getItem('order_search')) {
    recentOrderSearchKeywords.value = localStorage.getItem('order_search').split(',');
  }
}

const {
  submitOrderSearch
} = useSearch();

const searchBarDom = ref(null);
const orderSearchInputDom = ref(null);
async function toggleSearchType(type) {
  searchBarDom.value.toggleSearchType(type);
  if (type === 'order') {
    await nextTick();
    orderSearchInputDom.value.focus();
  }
}
</script>

<template>
  <div id="index">
    <header-mobile ref="headerDom" :is-logo="true" :is-menu="true" />
    <header-pc :is-search="false" />
    <loader-spinner v-if="isLoading" />
    <div v-if="!isLoading">
      <splide v-if="slides.length > 0" id="banner-splide" :options="bannerSplideOptions">
        <splide-slide v-for="(slide, key) in slides" :key="key">
          <picture>
            <source :srcset="slide.m" media="(max-width: 760px)" />
            <img :src="slide.pc" :alt="slide.title" />
          </picture>
        </splide-slide>
      </splide>
      <div class="content">
        <div v-if="posts && posts.length > 0" id="indexSectionPost" class="index-post row-gap-10">
          <div class="post-card card round-m">
            <router-link v-for="(post, key) in posts"
                          :key="key"
                          :to="{ name: 'post', params: { id: post.id } }"
                          class="post-item font-ss"
                          @click="setMetaTitle(post.title)">
              <p class="post-title flex-none">{{ post.title }}</p>
              <div class="post-content flex-1 flex justify-between">
                <p class="post-teaser">{{ stripTags(post.content) }}</p>
                <div class="post-date only-pc flex-none text-right">{{ formatDate(post.start_at) }}</div>
              </div>
            </router-link>
          </div>
          <router-link class="post-more font-ss text-right" :to="{ name: 'postList' }">
            {{ $t('post.more_post') }}
          </router-link>
        </div>
        <div id="index-search" class="row-gap-15">
          <div class="search-tab flex">
            <div id="tabProductDom"
                 class="tab-item"
                 :class="{ 'is-acted': searchStore.searchType == 'product' }"
                 @click="toggleSearchType('product')">票券</div>
            <div class="tab-item"
                 :class="{ 'is-acted': searchStore.searchType == 'order' }"
                 @click="toggleSearchType('order')">訂單</div>
          </div>
          <search-bar ref="searchBarDom" class="only-pc" />
          <div class="virtual-search gds-search round-pill flex only-m"
               @click="searchStore.searchType == 'order' ? '' : triggerMobileSearch()">
            <div v-if="searchStore.searchType == 'product'"
                  class="virtual-text flex-1">
              {{ $t('common.enter_keyword') }}
            </div>
            <input v-if="searchStore.searchType == 'order'"
                   ref="orderSearchInputDom"
                   type="text"
                   class="search-input flex-1"
                   :placeholder="$t('order.keyword_placeholder')"
                   v-model="searchStore.orderKeyword"
                   @keyup.enter="submitOrderSearch" />
            <button type="button"
                    class="search-btn round-circle"
                    @click="searchStore.searchType == 'order' ? submitOrderSearch() : ''">
              <gds-icon icon="search" />
            </button>
          </div>
          <p v-if="searchStore.searchType == 'product'" class="search-teaser font-s font-gray">
            {{ recentSearchKeywordsLabel }}
            <router-link :to="{ name: 'productList', query: { keyword: keyword }}"
                         v-for="keyword in recentSearchKeywords"
                         :key="keyword"
                         class="tag-keyword tag tag--secondary round-m">
              {{ keyword }}
            </router-link>
          </p>
          <p v-if="searchStore.searchType == 'order' && recentOrderSearchKeywords && recentOrderSearchKeywords.length > 0" class="search-teaser font-s font-gray">
            之前搜尋過：
            <router-link :to="{ name: 'orderList', query: { keyword: keyword }}"
                         v-for="keyword in recentOrderSearchKeywords"
                         :key="keyword"
                         class="tag-keyword tag tag--secondary round-m">
              {{ keyword }}
            </router-link>
          </p>
          <div class="search-source flex items-center justify-center">TripGDS</div>
        </div>
        <div class="row-gap-40">
          <div v-if="promoProduct && promoProduct.length > 0" id="indexSectionPromo">
            <h2 class="flex items-center gds-h1">{{ $t('index.leading_product') }}</h2>
            <div class="card-list">
              <card-product v-for="product in promoProduct"
                            :key="product.uuid"
                            :product="product" />
            </div>
          </div>
          <div v-if="latestProduct && latestProduct.length > 0" id="indexSectionLatest">
            <h2 class="flex items-center gds-h1">{{ $t('index.latest_product') }}</h2>
            <splide class="product-splide" :options="productSplideOptions">
              <splide-slide v-for="product in latestProduct" :key="product.uuid">
                <card-product :product="product" />
              </splide-slide>
            </splide>
          </div>
          <div v-if="topsaleProduct && topsaleProduct.length > 0" id="indexSectionTopSale">
            <h2 class="flex items-center gds-h1">{{ $t('index.hot_product') }}</h2>
            <splide class="product-splide" :options="productSplideOptions">
              <splide-slide v-for="product in topsaleProduct" :key="product.uuid">
                <card-product :product="product" />
              </splide-slide>
            </splide>
          </div>
          <div v-if="latestBuyProduct && latestBuyProduct.length > 0" id="indexSectionLatestBuy">
            <h2 class="flex items-center gds-h1">{{ $t('index.latest_buy') }}</h2>
            <splide class="product-splide" :options="productSplideOptions">
              <splide-slide v-for="product in latestBuyProduct" :key="product.uuid">
                <card-product :product="product" />
              </splide-slide>
            </splide>
          </div>
        </div>
      </div>
    </div>
    <transition-group name="fade-in" tag="div">
      <div v-if="isShowForcePost" key="bg" class="overlay-bg"></div>
      <div v-if="isShowForcePost" key="wrap" class="overlay-wrap is-popup">
        <div class="overlay-header">{{ post_popup_title }}</div>
        <div class="overlay-inner row-gap-20">
          <div v-html="post_popup_content" class="overlay-content"></div>
          <div class="flex justify-center col-gap-10">
            <div class="overlay-btn btn btn--primary flex items-center justify-center"
                 @click="clzForcePost">
              OK
            </div>
          </div>
        </div>
        <div class="overlay-clz flex items-center justify-center only-pc"
             @click="clzForcePost">
          <gds-icon icon="plus" />
        </div>
      </div>
    </transition-group>
    <footer-loader />
  </div>
</template>


<style lang="sass">
body.index
  background:
    image: url(/res/index-bg.jpg)
    repeat: repeat

.splide__arrow
  svg
    fill: currentColor

#banner-splide
  img
    width: 100%
  .splide__arrow
    font-size: $font-ll
    background-color: transparent
    color: rgba(#fff, .8)
  .splide__pagination
    bottom: -1.5em
  .splide__pagination__page.is-active
    background-color: $primary-color

.product-splide
  .splide__arrow
    font-size: $font-ll
    background-color: #fff
    color: $font-light
    box-shadow: 0 1px 5px rgba(153, 153, 153, .4)
    transition: .3s ease
    &:disabled
      display: none
    &:hover
      box-shadow: 0 3px 10px rgba(153, 153, 153, .6)
    svg
      width: .85em
      height: .85em
  .splide__pagination
    bottom: 0
  .splide__pagination__page.is-active
    background-color: $primary-color
  .splide__list
    margin-bottom: 15px !important

.index-post
  .post-card
    padding: 1em
    & > * + *
      padding-top: .8em
      border-top: 1px solid $border-lighter
      margin-top:.81em
  .post-item
    display: block
    text-decoration: none
    color: $font-light
    &:hover
      .post-title
        color: $secondary-color
  .post-title
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    color: $font-darker
  .post-teaser
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  .post-more
    display: block
    text-decoration: none
    color: $secondary-color
    &:hover
      text-decoration: underline

#index
  .index-title, .index-teaser
    font-weight: $font-medium
    color: $font-gray
    &.en
      word-spacing: 5px
      font-weight: $font-semibold
      font-family: Arial
  .index-title
    font-size: 45px
    &.en
      font-size: 36px
  .index-teaser
    font-size: 20px
    margin: 1em 0 2.5em
  .index-image
    width: 70%
  .index-desc
    line-height: 1.5
    letter-spacing: 1px
    font-family: Arial
    font-weight: $font-semibold
    font-size: $font-ss
    color: #888
    margin: 1.5rem 0

#index-search
  width: 90%
  margin: 3rem auto 5rem
  .search-tab
    .tab-item
      position: relative
      display: block
      text-decoration: none
      color: $font-darker
      padding: .6em 1em
      margin-right: .5em
      cursor: pointer
      &:last-child
        &:after
          right: null
          left: 0
      &.is-acted, &:hover
        &:after
          width: 100%
      &:after
        +psa(null, 0, 0)
        content: ''
        width: 0
        height: 2px
        background-color: $primary-color
        transition: .1s ease-in-out
  .search-type
    display: none
  .gds-dest
    text-align: left
  .virtual-search
    .virtual-text
      line-height: 45px
      color: #ccc
      padding-left: 1.2rem
    .search-input
      height: 45px
      padding-left: 1.2rem
    .search-btn
      width: 35px
      height: 35px
      margin: 5px
      -webkit-tap-highlight-color: transparent
  .search-teaser
    text-align: left
    margin-top: .8em
  .tag-keyword
    white-space: nowrap
    display: inline-block
    text-decoration: none
    padding: .2em .5em
    margin: .2em
  .search-source
    font-family: Arial
    font-weight: $font-semibold
    font-size: $font-ss
    color: #888
    margin-top: 1rem
    &::before, &::after
      content: ''
      width: 1em
      height: 2px
      background-color: #888
      border-radius: 2px
      margin: 0 .5em

+breakpoint(mobile)
  #index
    .content
      padding: 3em 0
    .gds-h1
      margin-left: 1rem
  .index-post
    margin: 0 1rem
    .post-title
      margin-bottom: .5em

+breakpoint(pad)
  .product-splide
    margin:  10px .5rem 0
    .splide__arrow--prev
      left: -1.6em
    .splide__arrow--next
      right: -1.6em
    .splide__pagination
      bottom: -1em
  #index
    .index-image
      width: 45%
  #index-search
    width: 75%

+breakpoint('min-width', 960)
  .index-post
    .post-item
      display: flex
    .post-content
      overflow: hidden
    .post-title
      width: 20%
    .post-date
      width: 100px

+breakpoint(pc)
  .product-splide
    margin: 10px -10px 0
    .splide__arrow--prev
      left: -2.5em
    .splide__arrow--next
      right: -2.5em
  #index
    .content
      max-width: calc(1180px + 6em)
      padding: 3em 3em 8em
    .index-image
      width: 36%
    .index-desc
      font-size: $font-s
      margin: 1.4rem 0 .8rem
  #index-search
    .gds-search-wrap
      .gds-search
        border: 1px solid $font-light
      .search-input
        height: 60px
        letter-spacing: 1px
        font-size: $font-m
        padding-left: 2rem
      .icon-dest
        top: 20px
      .search-clear
        right: 65px
        height: 60px
        width: 60px
      .search-btn
        width: 50px
        height: 48px
        font-size: $font-l
        color: #fff
        background-color: $primary-color
        margin: 6px 8px
      .gds-dest
        top: 60px
        .dest-wrap
          margin-bottom: 2rem
    .search-source
      margin-top: 1.5em
</style>
