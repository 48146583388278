<script setup>
import { ref, watch } from 'vue';
import { useDownloadStore } from '../stores/download';

const downloadStore = useDownloadStore();

const progress = ref(0);
watch(() => downloadStore.progress, value => {
  let timeoutID;
  if (value === 0)
    progress.value = 0;
  else if (value < 100) {
    timeoutID = setInterval((() => {
      if (progress.value < value)
        progress.value++;
      else
        clearTimeout(timeoutID);
    }), 100);
  } else {
    progress.value = 100;
    clearTimeout(timeoutID);
  }
});
</script>

<template>
  <div>
    <transition-group name="fade-in" tag="div">
      <div v-if="downloadStore.isDownloading" key="loader" class="footer-loader">
        <div class="loader-inner flex items-center col-gap-10">
          <div class="spinner"></div>
          <div>
            {{ $t('common.downloading') }}
            <span v-if="downloadStore.progress">{{ progress }}%</span>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style lang="sass">
.footer-loader
  +psf(null, 10px, 10px, null, $z-index-loading)
  background-color: rgba(0, 0, 0, .75)
  padding: 1em 2em
  border-radius: 5px
  .loader-inner
    text-align: center
    color: #fff
  .spinner
    width: 1em
    height: 1em
    border: 2px solid rgba(255, 255, 255, 0.3)
    border-top-color: #fff
    border-radius: 50%
    animation: spin 1s linear infinite

+breakpoint(mobile)
  .footer-loader
    width: calc(100% - 20px)
    bottom: 70px
</style>
