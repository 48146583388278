import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import setupInterceptors from './axios';
import router from './router';
import i18n from './i18n';
import { createGtm } from '@gtm-support/vue-gtm';

import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import AllRules from '@vee-validate/rules';

import flatpickr from 'flatpickr';

import 'virtual:svg-icons-register';
// import './assets/main.css';


const app = createApp(App);

setupInterceptors();
app.use(createPinia());
app.use(router);
app.use(i18n);

// Google Analytics & Google Tag Manager
let GTM_ID = 'GTM-PD3SP8B9';
if (window.location.hostname === 'www.ticketgds.com' || window.location.hostname === 'tmp.ticketgds.com') {
  GTM_ID = 'GTM-WJ4KC78C';
}
app.use(
  createGtm({
    id: GTM_ID,
    vueRouter: router
  })
);

// vee validate
app.component('VField', Field);
app.component('VForm', Form);
app.component('ErrorMessage', ErrorMessage);

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

defineRule('alpha_zhtw', AllRules['regex']);
defineRule('datetime', AllRules['regex']);
defineRule('flight', AllRules['regex']);
defineRule('mobile', AllRules['regex']);
defineRule('phone', AllRules['regex']);
defineRule('time', AllRules['regex']);

configure({
  generateMessage: ctx => {
    let params = {};
    if (ctx.rule.params.length > 0)
      params[ctx.rule.name] = ctx.rule.params[0];
    return i18n.global.t(`validation.${ctx.rule.name}`, params);
  },
  // validateOnInput: true
});

// flatpickr
flatpickr.localize(i18n.global.messages.value[i18n.global.locale.value]['flatpickr']);

router.isReady().then(() => {
  app.mount('#app');
});
