import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useSearchStore } from '../stores/search';
import { useGtm } from '@gtm-support/vue-gtm';
import axios from 'axios';

export function useSearch() {
  const router = useRouter();
  const searchStore = useSearchStore();

  const actedContinent = ref('');
  function setActedContinent() {
    actedContinent.value = '';
    if (searchStore.condition.type === 'country') {
      searchStore.destGrouping.forEach(continent => {
        if (actedContinent.value !== '')
          return;
        continent.countries.forEach(country => {
          if (country.code === searchStore.condition.country) {
            actedContinent.value = continent.name;
            return;
          }
        });
      });
    } else if (searchStore.condition.type === 'city') {
      searchStore.destGrouping.forEach(continent => {
        if (actedContinent.value !== '')
          return;
        continent.countries.forEach(country => {
          if (country.cities.includes(searchStore.condition.city)) {
            actedContinent.value = continent.name;
            return;
          }
        });
      });
    }
  }

  async function changeKeyword() {
    if (searchStore.condition.keyword && searchStore.condition.type === '') {
      await searchStore.checkKeywordIsDest();
      setActedContinent();
    }

    submitSearch();
  }

  function changeCountry(country) {
    searchStore.condition.keyword = searchStore.countryList[country];
    searchStore.condition.country = country;
    searchStore.condition.city = '';
    searchStore.condition.type = 'country';
    submitSearch();
  }

  function changeCity(city) {
    searchStore.condition.keyword = searchStore.cityList[city];
    searchStore.condition.country = '';
    searchStore.condition.city = city;
    searchStore.condition.type = 'city';
    submitSearch();
  }

  const gtm = useGtm();
  function submitSearch() {
    let query = {};
    if (searchStore.condition.keyword !== '') {
      query.keyword = searchStore.condition.keyword;

      let lastSearch = [];
      if (localStorage.getItem('search'))
        lastSearch = localStorage.getItem('search').split(',');
      if (!lastSearch.includes(searchStore.condition.keyword))
        lastSearch.unshift(searchStore.condition.keyword);
      if (lastSearch.length > 5)
        lastSearch.pop();

      localStorage.setItem('search', lastSearch.toString());

      gtm.trackEvent({
        event: 'search',
        eventModel: {
          search_term: searchStore.condition.keyword
        },
      });
    } else {
      delete query.keyword;
    }

    router.push({ name: 'productList', query: query });
  }

  // 訂單查詢
  function submitOrderSearch() {
    let query = {};
    if (searchStore.orderKeyword !== '') {
      query.keyword = searchStore.orderKeyword;

      let lastSearch = [];
      if (localStorage.getItem('order_search'))
        lastSearch = localStorage.getItem('order_search').split(',');
      if (!lastSearch.includes(searchStore.orderKeyword))
        lastSearch.unshift(searchStore.orderKeyword);
      if (lastSearch.length > 5)
        lastSearch.pop();

      localStorage.setItem('order_search', lastSearch.toString());

      gtm.trackEvent({
        event: 'search_order',
        eventModel: {
          search_term: searchStore.orderKeyword
        },
      });
    } else {
      delete query.keyword;
    }
    router.push({ name: 'orderList', query: query });
  }

  return {
    actedContinent,
    setActedContinent,
    changeKeyword,
    changeCountry,
    changeCity,
    submitSearch,
    submitOrderSearch,
  };
}
