<script setup>
import { useAuthStore } from '../../stores/auth';
import GdsIcon from '../GdsIcon.vue';

const CURRENCIES = ['TWD', 'RMB', 'USD', 'JPY', 'THB', 'KOW', 'GBP', 'AUD', 'CAD'];

const emit = defineEmits(['close-currency']);

const authStore = useAuthStore();

function changeCurrency(currency) {
  authStore.setCurrency(currency);
  emit('close-currency');
}
</script>

<template>
  <div class="header-menu-fixed">
    <ul class="select-menu">
      <li v-for="currency in CURRENCIES"
          :key="currency"
          class="menu-item flex items-center justify-between"
          :class="{ 'is-acted': currency == authStore.state.currency }"
          @click="changeCurrency(currency)">
        <span>{{ currency }}</span>
        <gds-icon v-if="currency == authStore.state.currency" icon="check" />
      </li>
    </ul>
  </div>
</template>

<style lang="sass">
.select-menu
  padding: 0 15px
  .menu-item
    color: $font-darker
    padding: 15px
    border-bottom: 1px solid $border-lighter
    &.is-acted
      color: $secondary-color
</style>
