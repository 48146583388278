import i18n from '../i18n';
import { useAuthStore } from '../stores/auth.js';
import { useCurrencyStore } from '../stores/currency.js';
import axios from 'axios';

export function useGdsFunc() {
  const { t } = i18n.global;
  const authStore = useAuthStore();
  const currencyStore = useCurrencyStore();

  function leadingZero(value) {
    return ('0' + value).slice(-2);
  }

  function formatDatetime(value) {
    const data = new Date(value);
    let month = data.getMonth() + 1;
    month = leadingZero(month);

    let day = data.getDate();
    day = leadingZero(day);

    let hour = data.getHours();
    hour = leadingZero(hour);

    let minute = data.getMinutes();
    minute = leadingZero(minute);

    return data.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }

  function formatDate(value) {
    const data = new Date(value);
    let month = data.getMonth() + 1;
    month = leadingZero(month);

    let day = data.getDate();
    day = leadingZero(day);

    return data.getFullYear() + '-' + month + '-' + day;
  }

  function formatNumber(value) {
    let parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (parts[1] && parts[1].length === 1)
      parts[1] += '0';

    return parts.join('.');
  }

  function stripTags(value) {
    return value.replace(/(<([^>]+)>)|&nbsp;/gi, '');
  }

  function isFloat(number) {
    let result = {
      number,
      digit: 0
    };
    if (number.toString().includes('.')) {
      result.number = Number(number.toString().replace('.', ''));
      result.digit = number.toString().split('.')[1].length;
    }
    return result;
  }

  function mathAdd(arg1, arg2) {
    let r1 = isFloat(arg1);
    let r2 = isFloat(arg2);

    let m = 10 ** Math.max(r1.digit, r2.digit);

    return (parseInt(arg1 * m) + parseInt(arg2 * m)) / m;
  }

  function mathMul(arg1, arg2) {
    let r1 = isFloat(arg1);
    let r2 = isFloat(arg2);

    return (r1.number * r2.number) / 10 ** (r1.digit + r2.digit);
  }

  function mathDiv(arg1, arg2) {
    let r1 = isFloat(arg1);
    let r2 = isFloat(arg2);

    return (r1.number / r2.number) * 10 ** (r2.digit - r1.digit);
  }

  function priceDecimal(currency, price) {
    let result = 0;
    switch (currency) {
      case 'TWD':
      case 'RMB':
      case 'JPY':
      case 'THB':
      case 'KOW':
        result = Math.ceil(price);
        break;
      default:
        // 無條件進位到小數第二位
        result = mathDiv(Math.ceil(mathMul(price, 100)), 100);
        break;
    }
    return result;
  }

  function priceExchange(value, oriCurrency) {
    let result = 0;
    // 因為第一次取 currency 會有問題，增加直接從 localStorage 取 currency
    if (!Object.keys(currencyStore.rates).length)
      currencyStore.rates = JSON.parse(localStorage.getItem('currency'));
    // 金額匯率換算
    if (authStore.state.currency === oriCurrency)
      result = value;
    else if (authStore.state.currency === 'TWD')
      result = mathMul(value, currencyStore.rates[oriCurrency]);
    else {
      result = mathDiv(
        mathMul(value, currencyStore.rates[oriCurrency]),
        currencyStore.rates[authStore.state.currency]);
    }

    // 進位設定
    result = priceDecimal(authStore.state.currency, result);

    return result;
  }

  async function errorHandler(error) {
    let errorParam;
    if (authStore.state.clientContact && authStore.state.clientContact.length > 0) {
      errorParam = {
        NAME: authStore.state.clientContact[0].name,
        PHONE: authStore.state.clientContact[0].phone
      };
    } else {
      errorParam = {
        NAME: '黃昌業',
        PHONE: '02-87939000#2564'
      };
    }

    try {
      let errorList;
      await import('../i18n/zh-hant.json').then(result => {
        errorList = Object.keys(result.default.error);
      });

      const errorCode = error.data.md_status;
      errorParam = { ...errorParam, ...error.data.error_param };

      if (errorList.includes(errorCode.toString()) && errorCode !== 11011) {
        // 翻譯檔（ i18n/zh-hant.json ）有對應錯誤代碼
        alert(
          t('error.' + errorCode, errorParam) + ' (' + t('error.code') + ': ' + errorCode + ')'
        );
      }
    } catch (e) {
      // 沒有對應錯誤代碼，顯示在 console error
      console.error(error);
      await axios.post('errorhandler', error);
      alert(t('error.api', errorParam));
    }
  }

  function setMetaTitle(title) {
    document.title = title + ' - ' + t('common.website_name');
  }

  return {
    leadingZero,
    formatDatetime,
    formatDate,
    formatNumber,
    stripTags,
    mathAdd,
    mathMul,
    mathDiv,
    priceDecimal,
    priceExchange,
    errorHandler,
    setMetaTitle
  };
}
