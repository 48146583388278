<script setup>
import { useAuthStore } from '../stores/auth';
import { useGdsFunc } from '../composables/useGdsFunc.js';
import GdsIcon from './GdsIcon.vue';
import CollectBtn from './CollectBtn.vue';

defineProps({
  isLink: {
    type: Boolean,
    default: true
  },
  product: {
    type: Object,
    required: true
  }
});

const authStore = useAuthStore();
const { formatNumber, priceExchange, setMetaTitle } = useGdsFunc();
</script>

<template>
  <router-link class="card card-product round-m"
               :class="{ 'is-disabled': !isLink }"
               :to="isLink ? { name: 'product', params: { uuid: product.uuid } } : ''"
               @click="setMetaTitle(product.name)"
               target="_blank">
    <div class="gds-img">
      <img :title="product.name" :src="product.image" loading="lazy" />
    </div>
    <div class="card-content">
      <div class="flex items-start justify-between">
        <div class="card-tags flex flex-wrap items-start">
          <span v-if="product.is_send == 1" class="inline-flex tag tag--secondary round-m">{{ $t('product.paper_voucher') }}</span>
          <span v-if="product.need_confirm == 0"
                class="inline-flex items-center tag tag--secondary round-m">
            <svg class="svg-icon tag-icon">
              <use xlink:href="#bolt" />
            </svg>
            <span>{{ $t('product.no_need_confirm') }}</span>
          </span>
          <span v-if="product.booking_required == 1"
                class="inline-flex tag tag--secondary round-m">{{ $t('product.booking_required') }}</span>
        </div>
        <collect-btn :prod-uuid="product.uuid" />
      </div>
      <div class="title">
        {{ product.name }}
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center col-gap-3">
          <gds-icon icon="map-b" />
          <div>
            {{ product.country }}
            -
            {{ product.city }}
          </div>
        </div>
        <div v-if="product.status == 1 && product.price && product.price.peer > 0"
             class="tag tag--default round-m">
          {{ $t('common.profit') }}
          <span class="profit">{{ Math.floor(product.price.avg_gm * 10) / 10 }} %</span>
        </div>
      </div>
      <div v-if="product.status == 1 && product.price && product.price.peer > 0"
           class="card-price flex items-end justify-between">
        <div>
          {{ $t('common.retail_price') }}
          <span class="gds-number">
            {{ authStore.state.currency }}
            {{ $t('product.price_about') }}
            {{ formatNumber(priceExchange(product.price.retail, product.price.currency)) }}
          </span>
        </div>
        <div class="gds-number">
          {{ authStore.state.currency }}
          {{ $t('product.price_about') }}
          <span class="price">
            {{ formatNumber(priceExchange(product.price.peer, product.price.currency)) }}
          </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style lang="sass">
.card-product
  display: block
  height: calc(100% - 22px)
  margin: 1rem
  overflow: hidden
.card-content
  font-size: $font-xs
  color: $font-light
  padding: .8rem
  .card-tags
    margin: 0 -.3em .5em
    .tag
      margin: 0.3em
  .title
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    line-height: 1.35
    font-weight: $font-black
    font-size: $font-l
    color: $font-darker
    margin-bottom: 1.2em
  .card-price
    padding-top: 1rem
    border-top: 1px solid $border-light
    margin-top: .5rem
  .sold-out
    color: $font-gray
    margin-top: 1.6em
  .price
    font-size: $font-l
    font-weight: $font-semibold
    color: $primary-color
  .profit
    font-weight: $font-semibold
    color: $primary-color

+breakpoint(pad)
  .card-list
    display: flex
    flex-wrap: wrap
    margin: 10px .5rem 0
    .card-product
      position: relative
      width: calc(50% - 1rem)
  .card-product
    margin: .5rem
    .title
      min-height: 48px
      font-size: $font-m

+breakpoint('min-width', 960)
  .card-list
    .card-product
      width: calc(33.33% - 1rem)

+breakpoint(pc)
  .card-list
    padding: 0
    margin: 10px -10px 0
    .card-product
      width: calc(33.33% - 1.25rem)
  .card-product
    margin: .625rem
</style>
