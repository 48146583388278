import { createI18n } from 'vue-i18n';
import MessageZhHant from './zh-hant.json';
import MessageZhHans from './zh-hans.json';
import MessageEn from './en.json';
import MessageJa from './ja.json';

import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';
import { Mandarin } from 'flatpickr/dist/l10n/zh.js';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';

const messages = {
  'zh-hant': { ...MessageZhHant, flatpickr: { ...MandarinTraditional } },
  'zh-hans': { ...MessageZhHans, flatpickr: { ...Mandarin } },
  en: MessageEn,
  ja: { ...MessageJa, flatpickr: { ...Japanese } }
};

let locale = 'zh-hant';
if (localStorage.getItem('gds-session')) {
  const session = JSON.parse(localStorage.getItem('gds-session'));
  locale = session.locale;
}

export default createI18n({
  legacy: false,
  locale,
  fallbackLocale: 'en',
  globalInjection: true,
  messages
});
