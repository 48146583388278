<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSearchStore } from '../../stores/search';
import { useSearch } from '../../composables/useSearch.js';
import GdsIcon from '../GdsIcon.vue';

defineProps({
  isShowDestMenu: Boolean
});

const emit = defineEmits(['show-dest-menu', 'hide-search-dest']);

const { locale } = useI18n();
const searchStore = useSearchStore();
const {
  actedContinent,
  setActedContinent,
  searchInputDom,
  changeKeyword,
  changeCountry,
  changeCity,
  clearSearch
} = useSearch();

onMounted(async () => {
  await searchStore.setDestinations(locale.value);
  if (searchStore.condition.keyword && searchStore.condition.type === '') 
    await searchStore.checkKeywordIsDest();
  
  setActedContinent();
});

const showContinent = ref('');
function changeSearchContinent(continent) {
  showContinent.value = continent;
  emit('show-dest-menu', continent);
}

function changeSearchKeyword() {
  changeKeyword();
  emit('hide-search-dest');
}

function changeSearchCountry(country) {
  changeCountry(country);
  emit('hide-search-dest');
}

function changeSearchCity(city) {
  changeCity(city);
  emit('hide-search-dest');
}
</script>

<template>
  <div class="header-menu-fixed">
    <div id="depth1">
      <div class="keyword row-gap-10">
        <div class="gds-search round-pill flex">
          <input v-if="!searchStore.condition.dest"
                 ref="searchInputDom"
                 type="text"
                 class="search-input flex-1"
                 :class="{'search-dest': searchStore.condition.type === 'country' || searchStore.condition.type === 'city'}"
                 :placeholder="$t('common.enter_keyword')"
                 v-model="searchStore.condition.keyword"
                 @input="searchStore.condition.type = ''"
                 @keyup.enter="changeSearchKeyword" />
          <gds-icon v-if="searchStore.condition.type === 'country' || searchStore.condition.type === 'city'"
                    class="icon-dest"
                    icon="map-b" />
          <div v-show="searchStore.condition.keyword"
               class="search-clear flex items-center justify-center"
               @click="clearSearch">
            <gds-icon icon="plus-circle-b" />
          </div>
          <button type="button" class="search-btn round-circle flex-none" @click="changeSearchKeyword">
            <gds-icon icon="search" />
          </button>
        </div>
        <p>{{ $t('common.input_keyword') }}</p>
      </div>
      <ul class="continent-list">
        <li v-for="continent in searchStore.destGrouping"
            :key="continent.name"
            class="continent-item flex items-center justify-between"
            :class="{ 'is-acted': continent.name === actedContinent }"
            @click="changeSearchContinent(continent.name)">
          <div class="name">{{ $t('continent.' + continent.name) }}</div>
          <gds-icon icon="angle-right" />
        </li>
      </ul>
    </div>
    <transition name="slide-left">
      <div v-if="isShowDestMenu" id="depth2" class="header-menu-fixed">
        <div v-show="continent.name === showContinent"
             v-for="continent in searchStore.destGrouping"
             :key="continent.name">
          <div v-for="country in continent.countries" :key="country.code" class="country-wrap">
            <div v-if="country.cities.length > 0"
                 class="country-name"
                 :class="{'is-acted':
                   searchStore.countryList[country.code] === searchStore.condition.keyword &&
                   searchStore.condition.country
                 }"
                 @click="changeSearchCountry(country.code)">
              {{ searchStore.countryList[country.code] }}
            </div>
            <div class="flex flex-wrap">
              <div v-for="city in country.cities"
                   :key="city.name"
                   class="city-item"
                   :class="{'is-acted':
                     searchStore.cityList[city] === searchStore.condition.keyword &&
                     searchStore.condition.city
                   }"
                   @click="changeSearchCity(city)">
                {{ searchStore.cityList[city] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="sass">
#depth1
  .keyword
    padding: 15px
    border-bottom: 1px solid $border-light
  .gds-search
    border: 1px solid #ddd
    .search-input
      padding-left: 1.2rem
    .search-btn
      font-size: $font-s
      color: #fff
      background-color: $primary-color
  p
    color:  $font-gray
  .continent-list
    padding: 10px 0
  .continent-item
    padding: 10px 15px
    &.is-acted
      color: $primary-color
    .name
      font-weight: $font-black
      font-size: $font-m
    .gds-icon
      font-size: $font-l
      color: $primary-color
#depth2
  color: $font-darker
  .country-wrap
    margin: 10px
  .country-name
    display: inline-block
    width: calc(50% - 10px)
    font-weight: $font-black
    text-decoration: underline
    font-size: $font-m
    padding: 7px
    border-radius: 3px
    margin: 5px
    &.is-acted
      font-weight: $font-normal
      text-decoration: none
      font-size: $font-ss
      color: #fff
      background-color: $primary-color
      padding: 8px
  .city-item
    width: calc(50% - 10px)
    padding: 8px
    margin: 3px 5px
    border-radius: 3px
    &.is-acted
      color: #fff
      background-color: $primary-color
</style>
