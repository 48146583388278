import { ref, reactive } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import { useGdsFunc } from '../composables/useGdsFunc.js';

export const useSearchStore = defineStore('search', () => {
  const condition = reactive({
    keyword: '',
    country: '',
    city: '',
    type: ''
  });

  const { errorHandler } = useGdsFunc();
  const destGrouping = ref([]);
  const countryList = ref([]);
  const cityList = ref([]);
  async function setDestinations(locale) {
    let lang = locale.toUpperCase();
    if (countryList.value.length == 0 && cityList.value.length == 0) {
      try {
        const resp = await axios.get('destinate/' + lang);
        if (resp.data.md_status === 0) {
          let grouping = [];
          let countries = {};
          let cities = {};
          resp.data.result.forEach((continent, continentKey) => {
            grouping[continentKey] = {
              name: continent.name,
              countries: []
            };
            continent.countries.forEach((country, countryKey) => {
              grouping[continentKey]['countries'].push({
                code: country.code,
                cities: []
              });
              countries[country.code] = country.translation;
              country.cities.forEach(city => {
                if (city.translation) {
                  grouping[continentKey]['countries'][countryKey]['cities'].push(city.name);
                  cities[city.name] = city.translation;
                }
              });
            });
          });

          destGrouping.value = grouping;
          countryList.value = countries;
          cityList.value = cities;
        } else
          throw resp;
      } catch (e) {
        errorHandler(e);
      }
    }
  }

  const cateGrouping = ref([]);
  const subCateList = ref([]);
  async function setCategories() {
    await import('../assets/category/zh-hant').then(result => {
      let grouping = [];
      let subCategories = {};
      let mainCateId;
      result.default.result.forEach(item => {
        subCategories[item.uuid] = item.sub_category;
        mainCateId = grouping.findIndex(cate => cate.name === item.category);
        if (mainCateId > -1) 
          grouping[mainCateId].sub.push(item.uuid);
        else {
          mainCateId = Object.keys(grouping).length;
          grouping.push({
            id: mainCateId,
            name: item.category,
            sub: [item.uuid]
          });
        }
      });

      cateGrouping.value = grouping;
      subCateList.value = subCategories;
    });
  }

  function setSearchSession() {
    const session = JSON.parse(localStorage.getItem('gds-session'));
    Object.assign(session, condition);
    localStorage.setItem('gds-session', JSON.stringify(session));
  }

  function checkKeywordIsDest() {
    if (Object.values(countryList.value).includes(condition.keyword)) {
      let index = Object.values(countryList.value).indexOf(condition.keyword);
      condition.country = Object.keys(countryList.value)[index];
      condition.city = '';
      condition.type = 'country';
    } else if (Object.values(cityList.value).includes(condition.keyword)) {
      let index = Object.values(cityList.value).indexOf(condition.keyword);
      condition.country = '';
      condition.city = Object.keys(cityList.value)[index];
      condition.type = 'city';
    } else {
      condition.country = '';
      condition.city = '';
      condition.type = 'keyword';
    }
  }

  function clearCondition() {
    condition.keyword = '';
    condition.country = '';
    condition.city = '';
    condition.type = '';
  }

  const filterDropdown = reactive({
    pc: '',
    mobile: ''
  });

  const searchType = ref('product');

  const orderKeyword = ref('');
  function clearOrderKeyword() {
    orderKeyword.value = '';
  }
  return {
    destGrouping,
    countryList,
    cityList,
    cateGrouping,
    subCateList,
    condition,
    setDestinations,
    setCategories,
    setSearchSession,
    checkKeywordIsDest,
    clearCondition,
    filterDropdown,
    searchType,
    orderKeyword,
    clearOrderKeyword
  };
});
