import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useCurrencyStore = defineStore('currency', () => {
  let now = new Date().getTime();
  let session = null;
  if (localStorage.getItem('currency')) 
    session = JSON.parse(localStorage.getItem('currency'));
  

  const rates = ref(session && now < session.expires ? session : {});

  if (Object.entries(rates.value).length === 0) 
    setCurrencyRate();
  

  async function setCurrencyRate() {
    const resp = await axios.get('currency');
    if (resp.data.md_status === 0 && Object.entries(resp.data.result).length > 1) {
      rates.value = resp.data.result;

      localStorage.setItem('currency', JSON.stringify(rates.value));
    }
  }

  return {
    rates
  };
});
