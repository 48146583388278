<script setup>
import { computed } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    required: true
  }
});

const ICON_CHARS = {
  'exclamation-b': 'e601',
  'map-b': 'e602',
  'plus': 'e604',
  'plus-circle-b': 'e607',
  'angle-left': 'e608',
  'minus': 'e60b',
  'mobile': 'e610',
  'check': 'e614',
  'list': 'e634',
  'envelope': 'e635',
  'member-b': 'e639',
  'file-check': 'e63c',
  'angle-right': 'e63e',
  'file-pdf': 'e63f',
  'info-circle': 'e641',
  'exclamation-circle-b': 'e642',
  'top': 'e64e',
  'filter-b': 'e67c',
  'search': 'e680',
  'booking': 'e6c7',
  'papber-voucher': 'e6c9',
  'features-b': 'e6ca',
  'clipboard-check': 'e6e4',
  'home': 'e92d',
  'user': 'e944',
  'ticket': 'e945'
};

const character = computed(() => {
  return '&#x' + ICON_CHARS[props.icon];
});
</script>

<template>
  <i class="gds-icon" v-html="character"></i>
</template>

<style lang="sass">
.gds-icon
  display: inline-block
  width: 1.2em
  height: 1.2em
  line-height: 1.2
  font-style: normal
  font-family: iconlion
  text-align: center
</style>
