<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '../../stores/auth';
import GdsIcon from '../GdsIcon.vue';

const emit = defineEmits([
  'hide-main-menu',
  'show-currency-menu',
  'show-locale-menu'
]);

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

function goPage(routeName) {
  if (routeName !== route.name)
    router.push({ name: routeName });
  emit('hide-main-menu');
}

function showSubMenu(type) {
  emit('show-' + type + '-menu');
}

function logout() {
  document.body.classList.remove('body-fixed');
  authStore.logout();
  router.push({ name: 'login' });
}
</script>

<template>
  <div id="menu-m" class="header-menu-fixed">
    <div class="profile row-gap-3">
      <div class="client">{{ authStore.state.client.name }}</div>
      <div class="user-name flex items-center col-gap-5">
        <gds-icon icon="user" />
        <span>{{ authStore.state.userName }}</span>
      </div>
    </div>
    <div class="menu-list">
      <div class="menu-item flex" @click="goPage('client')">
        {{ $t('title.client') }}
      </div>
      <div class="menu-item flex" @click="goPage('profile')">
        {{ $t('title.profile') }}
      </div>
      <div class="menu-item flex" @click="goPage('collectList')">
        {{ $t('title.collect_list') }}
      </div>
      <div class="menu-item flex" @click="goPage('orderList')">
        {{ $t('title.order_list') }}
      </div>
      <div class="menu-item flex" @click="goPage('bookingDraft')">
        {{ $t('title.booking_draft') }}
      </div>
      <div v-if="authStore.state.client.allow_car_rental" class="menu-item flex" @click="goPage('carList')">
        {{ $t('title.car_list') }}
      </div>
      <div v-if="authStore.isAdmin" class="menu-item flex" @click="goPage('accountList')">
        {{ $t('title.account_list') }}
      </div>
      <div class="menu-item flex" @click="goPage('postList')">
        {{ $t('title.post_list') }}
      </div>
      <div class="menu-item flex items-center justify-between" @click="showSubMenu('currency')">
        <div class="col-gap-10">
          <span>{{ $t('common.currency_label') }}</span>
          <span class="font-secondary">{{ authStore.state.currency }}</span>
        </div>
        <i class="gds-icon font-secondary"></i>
      </div>
      <div class="menu-item flex items-center justify-between" @click="showSubMenu('locale')">
        <div class="col-gap-10">
          <span>{{ $t('common.lang_label') }}</span>
          <span class="font-secondary">{{ $t('lang_name') }}</span>
        </div>
        <i class="gds-icon font-secondary"></i>
      </div>
      <div class="menu-item flex" @click="logout">
        {{ $t('common.logout') }}
      </div>
    </div>
  </div>
</template>

<style lang="sass">
#menu-m
  text-align: left
  .profile
    padding: 15px 30px
    background-color: $bg-color
    .client
      line-height: 1.3
      font-weight: $font-black
      font-size: $font-l
      color: $font-darker
    .user-name
      color: $font-gray
  .menu-list
    padding: 0 15px
  .menu-item
    text-decoration: none
    color: $font-darker
    padding: 15px 5px 15px 15px
    border-bottom: 1px solid $border-lighter
</style>
