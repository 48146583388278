<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../../stores/auth';
import GdsIcon from '../GdsIcon.vue';

const isShowTip = ref(false);
function toggleHotkeyTip () {
  isShowTip.value = !isShowTip.value;
}

const KEYCODES = ref({
  search: '/',
  order_list: 'I',
  collect_list: 'B',
  user_profile: 'U',
  client: 'E'
});

onMounted(async () => {
  document.addEventListener('keydown', clickPageHotkey);

  if (authStore.state.client.allow_car_rental) {
    KEYCODES.value.rental = 'K';
  }
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', clickPageHotkey);
});

const router = useRouter();
const authStore = useAuthStore();
function clickPageHotkey(e) {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const keyToCheck = isMac ? e.metaKey : e.ctrlKey;

  if (keyToCheck && e.key === 'i') {
    e.preventDefault();
    router.push({ name: 'orderList' });
  } else if (keyToCheck && e.key === 'b') {
    e.preventDefault();
    router.push({ name: 'collectList' });
  } else if (keyToCheck && e.key === 'u') {
    e.preventDefault();
    router.push({ name: 'profile' });
  } else if (keyToCheck && e.key === 'e') {
    e.preventDefault();
    router.push({ name: 'client' });
  } else if (keyToCheck && e.key === 'k' && authStore.state.client.allow_car_rental) {
    e.preventDefault();
    router.push({ name: 'carList' });
  }

  if (keyToCheck && ['i', 'b', 'u', 'e', 'k', '/'].includes(e.key)) {
    isShowTip.value = false;
  }
}
</script>

<template>
  <div class="footer-func">
    <a href="https://forms.gle/ZqSCY2hFQW5cgcWUA"
       class="func-btn tooltip round-circle flex items-center justify-center"
       target="_blank">
      <svg class="svg-icon">
        <use xlink:href="#message" />
      </svg>
      <div class="tooltip-text left round-m">{{ $t('footer.suggest') }}</div>
    </a>
    <div class="only-pc">
      <div class="func-btn tooltip round-circle flex items-center justify-center"
          @click="toggleHotkeyTip">
        <svg class="svg-icon">
          <use xlink:href="#keyboard" />
        </svg>
        <div class="tooltip-text left round-m">{{ $t('footer.hotkey_tip') }}</div>
      </div>
      <transition-group name="fade-in" tag="div">
        <div v-if="isShowTip" key="bg" class="overlay-bg only-pc" @click="toggleHotkeyTip"></div>
        <div v-if="isShowTip" key="wrap" class="overlay-wrap">
          <div class="overlay-header only-pc">{{ $t('footer.hotkey_tip') }}</div>
          <div class="overlay-inner row-gap-20">
            <p class="font-ss font-gray">window 使用 Ctrl，mac 使用 ⌘</p>
            <ul class="row-gap-20">
              <li v-for="(value, key) in KEYCODES" :key="key">
                <span class="key round-m">Ctrl <span class="font-light">/</span> ⌘</span> 
                + <span class="key round-m">{{ value }}</span> 
                : {{ $t('footer.' + key) }}
              </li>
            </ul>
          </div>
          <div class="overlay-clz flex items-center justify-center"
              @click="toggleHotkeyTip">
            <gds-icon icon="plus" />
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<style lang="sass">
.footer-func
  +psf(null, 1rem, 1rem, null, 1)
  .func-btn
    width: 45px
    height: 45px
    text-align: center
    font-size: $font-ll
    background-color: #fff
    color: $font-light
    cursor: pointer
    -webkit-tap-highlight-color: transparent
    box-shadow: 0 0 5px rgba(153, 153, 153, .4)
    transition: .3s ease
    margin-top: .5em
    &:hover
      color: $font-gray
      box-shadow: 1px 1px 8px rgba(153, 153, 153, .8)
      .tooltip-text
        opacity: .8
        visibility: visible
    .tooltip-text
      opacity: 0
      visibility: hidden
      transition: .5s ease
  .key
    display: inline-block
    min-width: 1.6em
    font-size: $font-ss
    font-weight: bold
    text-align: center
    background-color: $border-gray
    padding: .3em

+breakpoint('min-width', 1300)
  .footer-func
    right: 3rem
    bottom: 3rem
</style>