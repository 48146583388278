<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import GdsIcon from '../GdsIcon.vue';
import MainMenu from './MainMenu.vue';
import CurrencyMenu from './CurrencyMenu.vue';
import LocaleMenu from './LocaleMenu.vue';
import SearchMenu from './SearchMenu.vue';

defineProps({
  isHome: Boolean,
  isPrev: Boolean,
  isSearch: Boolean,
  isLogo: Boolean,
  isMenu: Boolean,
  isClose: Boolean
});

const emit = defineEmits(['close-menu']);

const { t } = useI18n();
const router = useRouter();
const hostName = ref(window.location.hostname);

function backPrevPage() {
  router.go(-1);
}

const isShowMenu = ref(false);
function toggleMainMenu() {
  isShowMenu.value = !isShowMenu.value;
  document.body.classList.toggle('body-fixed');
}

const headerTitle = ref('');
const isShowCurrencyMenu = ref(false);
function showCurrencyMenu() {
  isShowCurrencyMenu.value = true;
  headerTitle.value = t('common.choose_currency');
}
function hideCurrencyMenu() {
  isShowCurrencyMenu.value = false;
  headerTitle.value = '';
}

const isShowLocaleMenu = ref(false);
function showLocaleMenu() {
  isShowLocaleMenu.value = true;
  headerTitle.value = t('common.choose_lang_label');
}
function hideLocaleMenu() {
  isShowLocaleMenu.value = false;
  headerTitle.value = '';
}

const isShowSearchMenu = ref(false);
function showSearchMenu() {
  isShowSearchMenu.value = true;
  headerTitle.value = t('common.search');
  document.body.classList.add('body-fixed');
}
function hideSearchMenu() {
  isShowSearchMenu.value = false;
  headerTitle.value = '';
  document.body.classList.remove('body-fixed');
}

const isShowDestMenu = ref(false);
function showDestMenu(continent) {
  isShowDestMenu.value = true;
  headerTitle.value = t('continent.' + continent);
}
function hideDestMenu() {
  isShowDestMenu.value = false;
  headerTitle.value = t('common.search');
}

function closeMenu() {
  emit('close-menu', '');
}

defineExpose({
  showSearchMenu
});
</script>

<template>
  <div id="header-m" class="only-m">
    <div class="header-topbar flex">
      <div class="topbar-btn flex-none flex items-center justify-center">
        <router-link v-if="isHome && !isShowMenu && !isShowSearchMenu" to="/">
          <gds-icon icon="home" />
        </router-link>
        <div v-if="isPrev && !isShowMenu && !isShowSearchMenu" @click="backPrevPage">
          <gds-icon icon="angle-left" />
        </div>
        <div v-if="isShowLocaleMenu" @click="hideLocaleMenu">
          <gds-icon icon="angle-left" />
        </div>
        <div v-if="isShowCurrencyMenu" @click="hideCurrencyMenu">
          <gds-icon icon="angle-left" />
        </div>
        <div v-if="isShowSearchMenu && !isShowDestMenu" @click="hideSearchMenu">
          <gds-icon icon="angle-left" />
        </div>
        <div v-if="isShowDestMenu" @click="hideDestMenu">
          <gds-icon icon="angle-left" />
        </div>
      </div>
      <div class="topbar-btn"></div>
      <div class="flex-1 flex items-center justify-center">
        <slot v-if="
          !isShowMenu && !isShowCurrencyMenu && !isShowLocaleMenu && !isShowSearchMenu
        "></slot>
        <span v-else>{{ headerTitle }}</span>
        <div v-if="isLogo && !isShowMenu && !isShowCurrencyMenu && !isShowLocaleMenu && !isShowSearchMenu"
             class="flex items-center col-gap-10">
          <img src="/res/logo-white.svg" class="logo" />
          <span class="logo-title"><small v-if="hostName != 'www.ticketgds.com'">sandbox</small>{{ $t('common.website_name') }}</span>
        </div>
      </div>
      <div class="topbar-btn flex-none flex items-center justify-center">
        <div v-if="isSearch && !isShowMenu && !isShowSearchMenu" @click="showSearchMenu">
          <gds-icon icon="search" />
        </div>
      </div>
      <div class="topbar-btn flex-none flex items-center justify-center">
        <div v-if="isMenu && !isShowCurrencyMenu && !isShowLocaleMenu && !isShowSearchMenu"
             class="menu-btn"
             :class="{ 'is-acted': isShowMenu }"
             @click="toggleMainMenu">
          <span></span>
        </div>
        <div v-if="isClose" @click="closeMenu">
          <gds-icon icon="plus" class="icon-clz" />
        </div>
      </div>
    </div>
    <transition name="slide-down">
      <main-menu v-if="isShowMenu"
                 @show-currency-menu="showCurrencyMenu"
                 @show-locale-menu="showLocaleMenu"
                 @hide-main-menu="toggleMainMenu" />
    </transition>
    <transition name="slide-left">
      <currency-menu v-if="isShowCurrencyMenu"
                     @close-currency="
                       hideCurrencyMenu();
                       toggleMainMenu();
                     " />
    </transition>
    <transition name="slide-left">
      <locale-menu v-if="isShowLocaleMenu" />
    </transition>
    <transition name="slide-left">
      <search-menu v-if="isShowSearchMenu"
                   :is-show-dest-menu="isShowDestMenu"
                   @show-dest-menu="showDestMenu"
                   @hide-search-dest="
                     hideDestMenu();
                     hideSearchMenu();
                   " />
    </transition>
  </div>
</template>

<style lang="sass">
#header-m
  .header-topbar
    +psf(0, 0, null, null, $z-index-topbar)
    width: 100vw
    height: 45px
    text-align: center
    font-size: $font-m
    color: #fff
    background-color: $primary-color
    box-shadow: 0 1px 12px rgba(0, 0, 0, .4)
  .topbar-btn
    width: 45px
    height: 45px
    font-size: $font-l
    a
      color: #fff
  .menu-btn
    position: relative
    width: 45px
    height: 45px
    &.is-acted
      span
        opacity: 0
      &:before
        top: 21px
        transform: rotate(45deg)
      &:after
        top: 21px
        transform: rotate(-45deg)
    &:before
      content: ''
      top: 15px
    &:after
      content: ''
      top: 27px
    span
      top: 21px
    &:before, &:after, span
      +psa(null, null, null, 13px)
      display: block
      width: 18px
      height: 2px
      background-color: #fff
      border-radius: 1px
      transition: .3s ease
  .logo-link
    text-decoration: none
  .logo
    height: 25px
  .logo-title
    white-space: nowrap
    font-size: $font-m
    color: #fff
    small
      display: block
      font-size: .65rem
      text-align: left
  .tag-beta
    font-size: $font-xs
    color: #fff
    margin-top: 5px
  .icon-clz
    font-size: $font-xxl
    transform: rotate(45deg)
</style>
