<script setup>
import { useRouter } from 'vue-router';
import { useAuthStore } from '../../stores/auth';
import GdsIcon from '../GdsIcon.vue';

const LOCALES = ['zh-hant', 'zh-hans', 'en', 'ja'];

const router = useRouter();
const authStore = useAuthStore();

function changeLocale(localeCode) {
  authStore.setLocale(localeCode);
  router.go(0);
}
</script>

<template>
  <div class="header-menu-fixed">
    <ul class="select-menu">
      <li v-for="localeCode in LOCALES"
          :key="localeCode"
          class="menu-item flex items-center justify-between"
          :class="{ 'is-acted': localeCode == authStore.state.locale }"
          @click="changeLocale(localeCode)">
        <span>{{ $t('lang_name', null, { locale: localeCode }) }}</span>
        <gds-icon v-if="localeCode == authStore.state.locale" icon="check" />
      </li>
    </ul>
  </div>
</template>

<style lang="sass">
.select-menu
  padding: 0 15px
  .menu-item
    color: $font-darker
    padding: 15px
    border-bottom: 1px solid $border-lighter
    &.is-acted
      color: $secondary-color
</style>
