import { reactive } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

export const useCollectStore = defineStore('collect', () => {
  const collectList = reactive([]);

  async function setCollect() {
    const resp = await axios.get('favorite/id');

    if (resp.data.length > 0) {
      collectList.length = 0;
      collectList.push(...resp.data);
    }
  }

  return {
    collectList,
    setCollect
  };
});
