<script setup>
import FooterPc from './components/pc/FooterPc.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<template>
  <div>
    <router-view />
    <footer-pc v-if="route.name != 'about' && route.name != 'login'"/>
  </div>
</template>

<style lang="sass">
@import "@/assets/sass/main.sass"
</style>
